import React from 'react';
import { Div } from 'atomize';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { useStores } from '../../../store';

const CollapseButton: React.FC =
	observer(() => {
		const {
			layoutStore: { collapsed, toogleSide },
		} = useStores();
		return (
			<Div
				w="18px"
				h="22px"
				align="left"
				justify="center"
				rounded="xs"
				shadow="5"
				transition="cubic"
				style={{
					zIndex: 700,
				}}
				cursor="pointer"
				onClick={toogleSide}
			>
				{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
			</Div>
		);
	});
export default CollapseButton;
