import { makeAutoObservable, action, observable } from 'mobx';

import { Notification } from "../components/shared"
import client from './request';
import { IInputLogin } from './DataTypes';

export interface authStoreProps {
  username: string;
  loading: boolean;
  isAuthenticated: boolean;
  login: (data: IInputLogin) => any;
  logout: () => any;
}

class AuthStore implements authStoreProps {
  @observable
  username = localStorage.getItem('username') || '';

  @observable
  loading = false;

  @observable
  isAuthenticated = !!localStorage.getItem('authToken');

  constructor() {
    makeAutoObservable(this);
  }

  @action
  loadError = (err: any) => {
    this.loading = false;
  };

  @action
  login = (data: IInputLogin) => {
    this.loading = true;
    client.post('login', data)
      .then(this.loginSuccess)
      .catch(this.loadError);
  };

  @action
  logout = () => {
    localStorage.clear();
    this.username = '';
    this.isAuthenticated = false;
    this.loading = false;
  };

  @action
  loginSuccess = (result: any) => {
    if (!result.errors && result.status === 201) {
      localStorage.setItem('authToken', result.data.token);
      localStorage.setItem('username', result.data.username);
      this.username = result.data.username;
      this.isAuthenticated = true;
    } else {
      localStorage.clear();
      Notification({ type: 'warning', message: result.data.message });
    }
    this.loading = false;
  };
}

export default AuthStore;
