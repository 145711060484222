import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

type IDTextArea = {
	name: string;
	value?: string;
	defaultValue?: string;
	onChange: (name: string, val?: string) => any;
	disabled?: boolean;
	readOnly?: boolean;
};

export const DTextArea: React.FC<IDTextArea> = ({
	name,
	value,
	defaultValue,
	onChange,
	disabled,
	readOnly
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(name, event.target.value);
	return (
		// @ts-ignore
		<TextArea
			name={name}
			value={value}
			defaultValue={defaultValue}
			onChange={handleChange}
			disabled={disabled}
			readOnly={readOnly}
			style={{ height: 32 }}
		/>
	);
};
