import React, { ReactNode } from "react";
import { Form } from 'antd';

type IForm = {
    children: ReactNode;
    layout?: 'horizontal' | 'vertical' | 'inline';
    labelCol?: any
    wrapperCol?: any
}
// @ts-ignore
export const DForm: React.FC<IForm> = ({ children, ...props }) => <Form {...props}>{children}</Form>