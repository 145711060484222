import "react-table-v6/react-table.css";
import 'antd/dist/antd.css';
import "babel-polyfill";
import "./App.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const engine = new Styletron();

ReactDOM.render(
	<StyletronProvider value={engine}>
		{/* @ts-ignore */}
		<App />
	</StyletronProvider>,
	document.getElementById("root")
);

serviceWorker.unregister();
