import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Button, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useFormik } from 'formik';

import { DFormField, DForm } from '../components/shared';
import Auth from '../routes/Auth';
import { useStores } from '../store';
import { IInputLogin } from '../store/DataTypes';

type loginProps = {
	isAuthenticated: boolean;
};

const LoginForm: React.FC = observer(() => {
	const { authStore } = useStores();
	const { loading } = authStore;
	const login = (data: IInputLogin) => authStore.login(data);
	const {
		errors,
		setFieldValue,
		handleSubmit,
	} = useFormik<{
		manhanvien: string;
		password: string;
	}>({
		// enableReinitialize: true,
		initialValues: {
			manhanvien: '',
			password: '',
		},
		enableReinitialize: true,
		validate: (values: any) => {
			const errors: any = {};
			if (!values.manhanvien) {
				errors.manhanvien = 'Required';
			}
			if (!values.password) {
				errors.password = 'Required';
			}
			return errors;
		},
		onSubmit: (values) => {
			login({
				username: values.manhanvien.trim(),
				password: values.password.trim(),
			});
		},
	});
	return (
		<DForm layout="vertical">
			<DFormField
				itemCount={1}
				label="Mã đăng nhập"
				validateStatus={errors && errors.manhanvien ? 'error' : 'success'}
			>
				{/* @ts-ignore */}
				<Input
					autoComplete="false"
					prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
					placeholder="Mã"
					onChange={(e: any) => setFieldValue('manhanvien', e.target.value)}
				/>
			</DFormField>
			<DFormField
				itemCount={1}
				label="Mật khẩu"
				validateStatus={errors && errors.password ? 'error' : 'success'}
			>
				{/* @ts-ignore */}
				<Input
					autoComplete="false"
					prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
					type="password"
					placeholder="Mật khẩu"
					onChange={(e: any) => setFieldValue('password', e.target.value)}
				/>
			</DFormField>
			<DFormField itemCount={1}>
				<Button
					loading={loading}
					style={{ width: '100%' }}
					type="primary"
					htmlType="button"
					onClick={() => handleSubmit()}
				>
					Đăng Nhập
        		</Button>
			</DFormField>
		</DForm>
	);
});

const LoginPage: React.FC<loginProps & RouteComponentProps> = () => {
	return (
		<Modal visible={true} title="Welcome to QM Office" footer={null} closable={false}>
			<LoginForm />
		</Modal>
	);
};

export default Auth(LoginPage);
