import axios from "axios";
import { baseURL } from "../const/config"

function buildApi() {
    const instance = axios.create({
        baseURL,
        withCredentials: false
    });

    instance.interceptors.request.use(
        config => ({
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${window.localStorage.getItem('authToken')}`
            },
            // withCredentials: true
        })
    );

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && 401 === error.response.status) {
                window.localStorage.clear();
                window.location.href = error.response.data.url;
            }
            return Promise.reject(error);
        }
    );

    return instance;
}

export default buildApi();
