import { makeAutoObservable, action, observable } from 'mobx';
import moment, { Moment } from "moment";
import FileSaver from'file-saver';

import client from './request';
import { ISODateFormat, timeSpans, getMimeType } from '../utils';

export type IFilter = {
  TU_NGAY?: Moment;
  DEN_NGAY?: Moment;
  TIME_SPAN?: string;
  ID_SO_VAN_BAN: number,
  ID_LOAI_VAN_BAN: number,
  ID_CHU_DE: number,
  ID_LINH_VUC: number,
  SO_KY_HIEU: string,
  NOI_BAN_HANH: string,
  TRICH_YEU: string
};

export type VanBanDen = {
  ID_VAN_BAN: number,
  ID_SO_VAN_BAN: number,
  ID_LOAI_VAN_BAN: number,
  SO_KY_HIEU: string,
  SO_DI: number,
  NGAY_BAN_HANH: Date,
  TRICH_YEU: string,
  NOI_NHAN: string,
  GHI_CHU: string,
  NAM: number,
  NGUOI_GUI_VAN_BAN: string,
  NGUOI_KY_VAN_BAN: string,
  NGUOI_DUYET_VAN_BAN: string,
  SO_TRANG: string,
  SO_LUONG_BAN: number,
  ID_CHU_DE: number,
  ID_LINH_VUC: number,
  NGAY_HET_HIEU_LUC: Date,
  CHU_DE: string,
  LINH_VUC: string,
  TEN_LOAI_VAN_BAN: string,
  TEN_SO_VAN_BAN: string
}

export interface TableState {
  pageSizeOptions?: number;
  defaultPageSize?: number;
  minRows?: number;
  noDataText?: string;
  className?: string;
  pageSize: number;
  page: number;
  style?: any;
}

export interface vanBanDenStoreProps {
  loading: boolean;
  loadingDowload: boolean;
  loadingChitiet: boolean;
  vanbanden: VanBanDen[];
  loadData: () => void;
  download: (filePath: string, ext: string) => void;
  loadDataFile: (idvanban: number | string) => void;
  tableProps: TableState;
  updateFilter: (filter: IFilter) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number, page: number) => void;
  filter: IFilter;
  danhsachfile: FileName[]
}

export interface FileName {
  ID_FILE: number,
  TEN_TAI_LIEU: string,
  ID_VAN_BAN_DEN?: number,
  ID_VAN_BAN_DI?: number,
  ID_VAN_BAN_NOI_BO?: number,
  MO_RONG_FILE: string,
  TEN_FILE: string,
  MO_TA?: string
}

export const filterDefault = {
  TU_NGAY: timeSpans[1].start,
  DEN_NGAY: timeSpans[1].end,
  TIME_SPAN: '2',
  ID_SO_VAN_BAN: 0,
  ID_LOAI_VAN_BAN: 0,
  ID_CHU_DE: 0,
  ID_LINH_VUC: 0,
  NOI_BAN_HANH: '',
  SO_KY_HIEU: '',
  TRICH_YEU: '',
};

class VanBanDenStore implements vanBanDenStoreProps {

  private readonly apiUrl = '/vanbanden';

  @observable
  loading = false;

  @observable
  loadingDowload = false;

  @observable
  loadingChitiet = false;

  @observable
  danhsachfile: FileName[] = [];

  @observable
  tableProps: TableState = {
    defaultPageSize: 10,
    pageSize: 10,
    page: 0,
    className: "-striped -highlight",
    style: { marginBottom: '24px', width: '100%' }
  };

  @observable
  filter: IFilter = filterDefault;

  @observable
  vanbanden: VanBanDen[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onPageChange = (page: number) => this.tableProps = { ...this.tableProps, page: page }

  @action
  onPageSizeChange = (pageSize: number, page: number) => this.tableProps = { ...this.tableProps, pageSize: pageSize, page: page }

  @action
  loadError = (err: any) => {
    this.loading = false;
  };

  @action
  loadChitietError = (err: any) => {
    this.loadingChitiet = false;
  };

  @action
  updateFilter = (filter: IFilter) => {
    this.filter = filter;
    this.loadData();
  };

  @action
  refresh = () => {
    this.filter = filterDefault;
    this.loadData();
  };

  @action
  loadDataSuccess = (result: any) => {
    if (!result.errors && result.status === 201) {
      this.vanbanden = result.data.vanbanden
    }
    this.loading = false;
  };

  @action
  loadData = () => {
    this.loading = true;
    const form: any = {
      TU_NGAY: moment(this.filter.TU_NGAY).format(ISODateFormat).toString(),
      DEN_NGAY: moment(this.filter.DEN_NGAY).format(ISODateFormat).toString(),
      ID_CHU_DE: this.filter.ID_CHU_DE,
      ID_LINH_VUC: this.filter.ID_LINH_VUC,
      ID_LOAI_VAN_BAN: this.filter.ID_LOAI_VAN_BAN,
      ID_SO_VAN_BAN: this.filter.ID_SO_VAN_BAN,
      NOI_BAN_HANH: this.filter.NOI_BAN_HANH.trim(),
      SO_KY_HIEU: this.filter.SO_KY_HIEU.trim(),
      TRICH_YEU: this.filter.TRICH_YEU.trim(),

    }
    const queryString = Object.keys(form).map((k: string) => `${k}=${form[k]}`).join('&')
    client.get(this.apiUrl + '?' + queryString).then(this.loadDataSuccess)
      .catch(this.loadError);
  }

  @action
  loadDataFile = (idvanban: number | string) => {
    this.loadingChitiet = true;
    client.get(`chitietvanbanden/${idvanban}`).then(this.loadDataFileSuccess)
      .catch(this.loadChitietError);
  }

  @action
  loadDataFileSuccess = (result: any) => {
    if (!result.errors && result.status === 201) {
      this.danhsachfile = result.data.chitiet
    }
    this.loadingChitiet = false;
  };

  @action
  download = (filePath: string, ext: string) => {
    this.loadingDowload = true;
    const mime = getMimeType(ext);
    client.post('file', { linkFile: filePath, fileType: ext }, { responseType: 'blob' }).then(res => {
      // window.URL = window.URL || window.webkitURL;
      // const url = window.URL.createObjectURL(new Blob([res.data]));
      // const link = document.createElement('a');
      const filename = filePath.split('/')[1] + ext;
      // link.style.display = 'none';
      // link.href = url;
      // link.setAttribute('download', filename);
      // document.body.appendChild(link);
      // link.target = '_blank';
      // link.click();
      // link.remove();
      // // const filename = filePath.split('/')[1] + ext;
      const blob = mime ? new Blob([res.data], { type: mime }) : new Blob([res.data]);
      FileSaver.saveAs(blob, filename);
    }).catch(err => {
      console.log(err.message)
    })
    this.loadingDowload = false;
  }

  @action
  view = (filePath: string, ext: string) => {
    const mime = getMimeType(ext);
    if (mime) {
      client.post('file', { linkFile: filePath, fileType: ext }, { responseType: 'blob' }).then(res => {
        window.URL = window.URL || window.webkitURL;
        const blob = new Blob([res.data], { type: mime });
        // const url = window.URL.createObjectURL(blob, { type: mime }));
        // window.open(url, '_blank')
        // window.URL.revokeObjectURL(url);
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // link.href = url;
        // link.setAttribute('download', filePath.split('/')[1] + ext);
        // document.body.appendChild(link);
        // link.target = '_blank';
        // link.click();
        // link.remove();
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.target = '_blank';
        // tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200)
      }).catch(err => console.log(err.message))
    }
  }
}

export default VanBanDenStore;
