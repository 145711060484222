import React from 'react';
import { ThemeProvider, StyleReset } from 'atomize';
import { Provider } from "mobx-react";
import Routes from './routes';
import theme from './const/theme';
import { useStores } from './store';
const App: React.FC = () => {
	const stores = useStores();
	return (
		<ThemeProvider theme={theme}>
			<Provider {...stores} >
				<StyleReset />
				<Routes />
			</Provider>
		</ThemeProvider>
	);
};

export default App;
