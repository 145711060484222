import React, { PureComponent } from "react";
import classnames from "classnames";
import { Icon } from "atomize";

const defaultButton = (props: any) => (
	<button type="button" {...props} className="-btn">
		{props.children}
	</button>
);

type IReport = {
	pages: any;
	page: any;
	showPageSizeOptions: any;
	pageSizeOptions: any;
	pageSize: any;
	showPageJump: any;
	canPrevious: any;
	canNext: any;
	onPageSizeChange: any;
	className: any;
	PreviousComponent: any;
	NextComponent: any;
	onPageChange: any;
	rowsSelectorText: any;
	pageJumpText: any;
	style: any;
};
type IState = {
	page: any;
};
export class DTablePagination extends PureComponent<IReport, IState> {
	constructor(props: IReport) {
		super(props);
		this.getSafePage = this.getSafePage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.applyPage = this.applyPage.bind(this);

		this.state = {
			page: props.page
		};
	}

	componentDidUpdate(nextProps: IReport) {
		if (this.props.page !== nextProps.page) {
			this.setState({ page: nextProps.page });
		}
	}

	getSafePage(page: any) {
		if (Number.isNaN(page)) {
			page = this.props.page;
		}
		return Math.min(Math.max(page, 0), this.props.pages - 1);
	}

	changePage(page: any) {
		const pageNumber = this.getSafePage(page);
		this.setState({ page: pageNumber });
		if (this.props.page !== page) {
			this.props.onPageChange(page);
		}
	}

	applyPage(e: any = null) {
		if (e) {
			e.preventDefault();
		}
		const page = this.state.page;
		this.changePage(page === "" ? this.props.page : page);
	}

	render() {
		const {
			// Computed
			pages,
			// Props
			page,
			showPageSizeOptions,
			pageSizeOptions,
			pageSize,
			// showPageJump,
			canPrevious,
			canNext,
			onPageSizeChange,
			className,
			PreviousComponent = defaultButton,
			NextComponent = defaultButton
		} = this.props;
		return (
			<div
				className={classnames(className, "-pagination")}
				style={this.props.style}
			>
				<div className="-previous">
					<PreviousComponent
						onClick={() => {
							if (!canPrevious) return;
							this.changePage(page - 1);
						}}
						disabled={!canPrevious}
					>
						<Icon name="LeftArrow" size="20px" />
					</PreviousComponent>
				</div>
				<div className="-center">
					<span className="-pageInfo" style={{ fontSize: 12 }}>
						<span className="-currentPage">{page + 1}</span>
						<span className="-totalPages"> / {pages || 1}</span>
					</span>
					{showPageSizeOptions && (
						<span
							className="select-wrap -pageSizeOptions"
							style={{ fontSize: 12 }}
						>
							<select
								style={{ width: 85 }}
								aria-label={this.props.rowsSelectorText}
								onChange={e => onPageSizeChange(Number(e.target.value))}
								value={pageSize}
							>
								{pageSizeOptions.map((option: any, i: any) => (
									<option key={i} value={option}>
										{`${option} ${"hàng"}`}
									</option>
								))}
							</select>
						</span>
					)}
				</div>
				<div className="-next">
					<NextComponent
						onClick={() => {
							if (!canNext) return;
							this.changePage(page + 1);
						}}
						disabled={!canNext}
					>
						<Icon name="RightArrow" size="20px" />
					</NextComponent>
				</div>
			</div>
		);
	}
}
