import React from "react";
import { DatePicker } from 'antd';
import { Moment } from "moment";
import { covertToFormat, covertToFormatNotime } from "../../utils";
// import { DCauHinh } from "../../store/graphql.schema";

export interface IDDatePicker {
	name: string;
	value?: Moment | null;
	defaultValue?: Moment;
	placeholder?: string;
	disabled?: boolean;
	showTime?: boolean;
	allowClear?: boolean;
	formart?: any;
	onChange: (name: string, date: Moment | null) => void;
}

export const DDatePicker: React.FC<IDDatePicker> = React.memo(({
	name,
	value,
	defaultValue,
	onChange,
	showTime,
	disabled,
	formart,
	placeholder,
	allowClear
}) => {
	const handleChange = (date: Moment | null, _: string) => {
		onChange(name, date);
	};
	return (
		// @ts-ignore
		<DatePicker
			showTime={showTime}
			// @ts-ignore
			value={value}
			// @ts-ignore
			defaultValue={defaultValue}
			format={showTime ? covertToFormat(formart) : covertToFormatNotime(formart)}
			onChange={handleChange}
			placeholder={placeholder}
			disabled={disabled}
			style={{ width: "100%" }}
			allowClear={allowClear}
		/>
	);
});
