// eslint-disable-next-line
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from '../routes/Auth';
import DashboardLayout from '../components/Layout/DashboardLayout';
import { LazyLoader } from '../components/shared/loader';
import { reports, DASHBOARD_ROOT, PRIVATE_ROOT } from '../const/config';
import { useStores } from '../store';

const DashboardBoundary: React.FC<any> = (props) => {
  const {
    globalstore: { loadData },
  } = useStores();
  useEffect(() => {
    loadData();
  }, [loadData]);
  const renderComponent = () => {
    const item = reports.find((i: any) => i.slug === props.match.params.id);
    if (item) {
      let Module = React.lazy(() => import('./Home'));
      if (item.id !== 0) {
        Module = React.lazy(() => import(`./${item.module}`));
      }
      return <Module />;
    }
    return <Redirect from={PRIVATE_ROOT} to={DASHBOARD_ROOT} />;
  };
  return (
    <DashboardLayout>
      <LazyLoader>{renderComponent()}</LazyLoader>
    </DashboardLayout>
  );
};

class Dashboard extends React.PureComponent {
  static isPrivate = true;
  render() {
    return (
      // @ts-ignore
      <Switch>
        {/* @ts-ignore */}
        <Route path="/dapp/:id" component={DashboardBoundary} />
        <Redirect from={PRIVATE_ROOT} to={DASHBOARD_ROOT} />
      </Switch>
    );
  }
}

export default Auth(Dashboard);
