import React from "react";
import { Div } from "atomize";

import Auth from "../routes/Auth";

class NotFoundPage extends React.Component {
    static isPrivate = false;
    render() {
        return (
            <Div>
                <span>NotFound</span>
            </Div>
        );
    }
}

export default Auth(NotFoundPage);
