export * from "./DataInit";
export * from "./NumberFormat";

export const removeDuplicates = (myArr: Array<any>, prop: any) => {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
};

export const findByID = (source: any[], key: string, id: string) => source.find((item: any) => item[key] === id);

const fileExt = ['.pdf', '.png', '.jpg', '.jpeg', '.gif'];
const mimeTypes = ['application/pdf','image/png','image/jpeg','image/jpeg','image/gif']
export const getMimeType = (ext: string) => {
    if(!ext) return null;
    else {
        const extension = ext.toLowerCase();
        const idx = fileExt.findIndex(e => e === extension);
        if(idx || idx === 0) {
            return mimeTypes[idx];
        }
        return null;
    }
}