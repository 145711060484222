import React from 'react';
import { Menu, Layout } from 'antd';
import { observer } from 'mobx-react';
import { Div } from 'atomize';
import { AppstoreOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
// import LogoutButton from './LogoutButtonMobile';

import { useStores } from '../../../store';
import { reports, settings } from '../../../const/config';
import CollapseButton from './CollapseButton';
import { AppLogo } from './AppLogo';

const { Sider } = Layout;

export const DMenu: React.FC = () => {
	const {
		authStore: { logout }
	} = useStores();
	const location = useLocation();
	return (
		// @ts-ignore
		<Menu
			selectedKeys={[location.pathname]}
			defaultOpenKeys={['report']}
			mode="inline"
			style={{ height: '100%' }}
		>
			{/* @ts-ignore */}
			<Menu.Item key="/dapp/home">
			{/* @ts-ignore */}
				<AppstoreOutlined />
				<span>Trang chủ</span>
				<Link to="/dapp/home" />
			</Menu.Item>
			{reports
				.filter((i) => i.id !== 0)
				.map((i: any) => (
					// @ts-ignore
					<Menu.Item key={`/dapp/${i.slug}`}>
						<i.icon style={{ color: i.color }} />
						<span style={{ fontSize: 13 }}>{i.title}</span>
						<Link to={`/dapp/${i.slug}`} />
					</Menu.Item>
				))}
				{/* @ts-ignore */}
			<Menu.Item key="logout" onClick={logout}>
				{/* @ts-ignore */}
				<PoweroffOutlined style={{ color: 'red' }}/>
				<span style={{ fontSize: 13 }}>Đăng xuất</span>
			</Menu.Item>
			{/* </SubMenu> */}
		</Menu>
	);
};

export const SiderMenu: React.FC = observer(() => {
	const {
		layoutStore: { collapsed },
		authStore: { username },
	} = useStores();
	return (
		<Div
			bg="color1"
			w={collapsed ? settings.menu[1] : settings.menu[0]}
			h="100%"
			d={{ xs: 'none', md: 'block' }}
			transition="cubic"
		>
			{/* @ts-ignore */}
			<Sider
				width={304}
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				className="site-layout-background"
			>
				<Div
					d="flex"
					align="center"
					justify={collapsed ? 'space-around' : 'space-between'}
					p="13px"
					style={{ borderBottom: '1px solid black' }}
				>
					{/* @ts-ignore */}
					{!collapsed && <AppLogo username={username} />}
					{/* @ts-ignore */}
					<CollapseButton />
				</Div>
				{/* @ts-ignore */}
				<DMenu />
			</Sider>
		</Div>
	);
});
