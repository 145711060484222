import { message as toaster } from 'antd';

export interface INotification {
	type?: "success" | "warning";
	message: string;
	description?: string;
}

export const Notification = (args: INotification) => {
	const { type, message } = args;
	switch (type) {
		case "success":
			toaster.success(message)
			break;
		case "warning":
			toaster.warning(message)
			break;
		default:
			toaster.error(message)
			break;
	}
};
