import React from 'react';
import { Div } from 'atomize';
import { observer } from 'mobx-react';

import { VStack } from '../../shared';
import { settings } from '../../../const/config';
import { useStores } from '../../../store';

type props = {
	children?: any;
};

const MainContent: React.FC<props> =
	observer(({ children }) => {
		const {
			layoutStore: { collapsed },
		} = useStores();
		return (
			<Div
				w={{
					xs: '100%',
					md: `calc(100% - ${collapsed ? settings.menu[1] : settings.menu[0]})`,
				}}
				transition="cubic"
			>
				<VStack h="100%" w="100%">
					<Div w="100%" h="100%">
						<Div w="100%" h="calc(100% - 30px)">
							{children}
						</Div>
						<Div bg="gray200" h="30px" textAlign="center"><span>Copyright @2021 Sen Việt Công Thương - by Quang Minh Solutions (QMS)</span></Div>
					</Div>
				</VStack>
			</Div>
		);
	});

export default MainContent;
