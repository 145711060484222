import React from "react";
import { Select } from 'antd';
const { Option } = Select;

type IDSelect = {
	name: string;
	options: Array<any>;
	value?: string;
	defaultValue?: string;
	onChange: (name: string, val: string) => any;
	disabled?: boolean;
	labelKey: string;
	valueKey: string;
	placeholder?: string;
	allowClear?: boolean;
	showSearch?: boolean;
	addOptionAll?: boolean;
	style?: any;
};

const defaultStyle = { width: '100%' };

export const DSelect: React.FC<IDSelect> = React.memo(
	({ name, value, defaultValue, onChange, options, labelKey, valueKey, disabled, placeholder, allowClear, showSearch, addOptionAll, style }) => {
		const handleChange = (val: string, _: any) => onChange(name, val);
		const data = addOptionAll ? [{ [valueKey]: "0", [labelKey]: "Tất cả" }, ...options] : options;
		return (
			// @ts-ignore
			<Select
				value={value}
				defaultValue={defaultValue}
				onChange={handleChange}
				disabled={disabled}
				placeholder={placeholder}
				allowClear={allowClear}
				showSearch={showSearch}
				filterOption={(input, option: any) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				style={style ? style : defaultStyle}
			>
				{/* @ts-ignore */}
				{data.map(i => <Option key={i[valueKey]} value={i[valueKey]}>{i[labelKey]}</Option>)}
			</Select>
		);
	}
);
