import React from "react";
import { Input } from "antd";

type IDTextInput = {
	name: string;
	value?: string;
	placeholder?: string;
	defaultValue?: string;
	onChange: (name: string, val?: string) => any;
	disabled?: boolean;
	readOnly?: boolean;
};

export const DTextInput: React.FC<IDTextInput> = ({
	name,
	defaultValue,
	value,
	onChange,
	disabled,
	readOnly,
	placeholder
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(name, event.target.value)
	return (
		// @ts-ignore
		<Input
			value={value}
			defaultValue={defaultValue}
			onChange={handleChange}
			disabled={disabled}
			placeholder={placeholder}
			readOnly={readOnly}
			width="100%"
		/>
	);
};
