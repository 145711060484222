import numeral from 'numeral';
// import { DCauHinh } from '../store/graphql.schema';
// load a locale
numeral.register('locale', 'vi', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
});

// switch between locales

export const covertNumber = (thapPhan = 2, str: string) => {
  if (str === null) return "";
  if (!!!str) str = '0';
  const rexgLength = thapPhan;
  let rexg = '0.';
  for (let i = 0; i < rexgLength; i++) rexg = rexg + '0';
  rexg = rexg + '[,]';
  return numeral(parseFloat(str)).format(rexg);
};

export const covertNumbertoPer = (str: string) => {
  if (str === null) return "";
  if (!!!str) str = '0';
  const rexgLength = 2;
  let rexg = '0.';
  for (let i = 0; i < rexgLength; i++) rexg = rexg + '0';
  rexg = rexg + '[,]';
  return numeral(parseFloat(str)).format(rexg) + ' %';
};

export const covertToMoney = (thapPhan = 0, str: string) => {
  if (str === null) return "";
  if (!!!str) str = '0';
  const rexgLength = thapPhan;
  let rexg = '0,0.';
  for (let i = 0; i < rexgLength; i++) rexg = rexg + '0';
  return numeral(parseFloat(str)).format(rexg);
};
export const covertMFormat = (
  cauhinh?: any,
  str?: string | number,
  precision?: number,
) => {
  if (str === undefined) return "";
  if (str === null) return "";
  if (!!!str) return "0";
  const source = str.toString();
  if (cauhinh && cauhinh.DINH_DANG_SO_THAP_PHAN === ',') numeral.locale('vi');
  else numeral.locale('en');
  const rexgLength = precision
    ? precision
    : cauhinh && cauhinh.THAP_PHAN_TIEN
      ? cauhinh.THAP_PHAN_TIEN
      : 0;
  let rexg = '0,0.';
  for (let i = 0; i < rexgLength; i++) rexg = rexg + '0';
  return numeral(parseFloat(source)).format(rexg);
};
export const covertNFormat = (
  str?: string,
  precision?: number,
) => {
  if (str === undefined) return "";
  if (str === null) return "";
  if (!!!str) return "0";
  const source = str;
  const rexgLength = precision
    ? precision
    : 2;
  let rexg = '0,0.';
  for (let i = 0; i < rexgLength; i++) rexg = rexg + '0';
  return numeral(parseFloat(source)).format(rexg);
};
