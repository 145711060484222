import React from 'react';
import { Div } from 'atomize';

import { HStack } from '../../shared';
import MenuMobile from './MobileMenu';
import { SiderMenu } from './SiderMenu';
import MainContent from './MainContent';

type layoutProps = {
	children?: any;
};

const Layout: React.FC<layoutProps> = ({ children }) => {
	return (
		<Div className="app">
			<HStack w="100%" h="100vh" pos="absolute">
				<SiderMenu />
				<MainContent>{children}</MainContent>
			</HStack>
			<MenuMobile />
		</Div>
	);
};

export default Layout;
