// import libs
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import components
import routes from "./routes";

const Routes: React.FC = () => (
    // @ts-ignore
    <Router>
        <Switch>
            {routes.map((route: any, i: number) => (
                <Route key={i} {...route} />
            ))}
        </Switch>
    </Router>
);

export default Routes;
