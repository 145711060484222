import React from 'react';
import { FormLoader, loaderProps } from './loader';
import { Form } from 'antd';

type IFormField = {
	loading?: boolean;
	children?: any;
	label?: string;
	itemWidth?: string;
	hasFeedback?: boolean;
	help?: string;
	validateStatus?: 'success' | 'warning' | 'error' | 'validating';
	wrapperCol?: any
	style?: any
};

export const DFormField: React.FC<IFormField & loaderProps> = React.memo(
	({
		loading,
		width,
		height,
		itemCount,
		children,
		label,
		hasFeedback,
		validateStatus,
		help,
		itemWidth,
		style,
		...props
	}) => {
		return loading ? (
			// @ts-ignore
			<FormLoader
				itemCount={itemCount}
				width={width}
				height={height ? height : "32px"}
				style={{ marginBottom: 7 }}
			/>
		) : (
			// @ts-ignore
				<Form.Item
					style={style ? style : { padding: "0px 5px", width: itemWidth ? itemWidth : undefined, position: 'relative' }}
					label={label}
					hasFeedback={hasFeedback}
					validateStatus={validateStatus}
					help={help}
					{...props}
				>
					{children}
				</Form.Item>
			);
	},
);
