import React from 'react';
import { Div, Text } from 'atomize';
import { Avatar, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LogoutButton from './LogoutButton';

export const AppLogo: React.FC<{ username?: string }> = ({ username }) => (
	<Div d="flex">
		<Popover placement="right" content={() => <LogoutButton />}>
			<Avatar
				style={{ backgroundColor: '#87d068', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
				shape="square"
				size="small"
				icon={<UserOutlined />}
			/>
		</Popover>
		{username && (
			<Div p={{ l: '5px' }} d="flex" align="center">
				<Text>{username}</Text>
			</Div>
		)}
	</Div>
);
