import { makeAutoObservable, action, observable } from 'mobx';

import client from './request';

export interface SoVanBan {
  ID_SO_VAN_BAN: number;
  TEN_SO_VAN_BAN: string;
  LOAI_SO_DI_DEN: boolean;
  NAM: number;
}

export interface LoaiVanBan {
  ID_LOAI_VAN_BAN: number;
  MA_LOAI_VAN_BAN: string;
  TEN_LOAI_VAN_BAN: string;
  // MO_TA": null
}

export interface ChuDe {
  ID_CHU_DE: number;
  CHU_DE: string;
  // MO_TA": null
}

export interface LinhVuc {
  ID_LINH_VUC: number;
  LINH_VUC: string;
  // MO_TA": null
}

export interface PhongBan {
  ID_PHONG_BAN: number;
  TEN_PHONG_BAN: string;
}

export interface IGFilter {
    sovanban: SoVanBan[],
    loaivanban: LoaiVanBan[],
    chude: ChuDe[],
    linhvuc: LinhVuc[],
    phongban: PhongBan[]
}

export interface Props {
  loading: boolean;
  filters?: IGFilter;
}


class GlobalStore implements Props {

  private readonly apiUrl = '/filters';

  @observable
  loading = false;

  @observable
  filters: IGFilter = { sovanban: [],
    loaivanban: [],
    chude: [],
    linhvuc: [],
    phongban: [] };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  loadError = (err: any) => {
    this.loading = false;
  };

  @action
  loadDataSuccess = (result: any) => {
    if (!result.errors && result.status === 201) {
      this.filters = result.data;
    }
    this.loading = false;
  };

  @action
  loadData = () => {
    client.get(this.apiUrl).then(this.loadDataSuccess)
      .catch(this.loadError);
  }
}

export default GlobalStore;
