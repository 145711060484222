import React, { Suspense } from "react";
import ContentLoader from "react-content-loader";
import { Div, Icon } from "atomize";

export interface loaderProps {
	inputType?: String;
	itemCount: number;
	width?: string;
	height?: string;
	style?: any;
}

export const FormLoader: React.FC<loaderProps> = ({
	inputType,
	height,
	width,
	itemCount,
	style
}) => {
	const renderItems = (): Array<any> => {
		let items: Array<any> = [];
		for (let i = 0; i < itemCount; i++) {
			items.push(
				<ContentLoader
					key={i}
					style={{
						display: "flex",
						alignItems: "center",
						width: width ? width : "100%",
						height: height ? height : "32px",
						padding: "0 5px",
						...style
					}}
				>
					<rect x="0" y="0" width="100%" height="100%" />
				</ContentLoader>
			);
		}
		return items;
	};
	const renderItemsButton = (): Array<any> => {
		let items: Array<any> = [];
		for (let i = 0; i < itemCount; i++) {
			items.push(
				<ContentLoader
					style={{
						display: "flex",
						alignItems: "center",
						width: width ? width : "100%",
						height: height ? height : "34px",
						padding: "0 5px",
						...style
					}}
				>
					<rect x="0" y="0" width="100%" height="100%" />
				</ContentLoader>
			);
		}
		return items;
	};
	switch (inputType) {
		case "button":
			return <>{renderItemsButton().map(i => i)}</>;

		default:
			return <>{renderItems().map(i => i)}</>;
	}
};

export const LazyLoader: React.FC<{ children: any }> = ({ children }) => (
	<Div w="100%" h="100%">
		<Suspense
			fallback={
				<Div w="100%" h="80vh" d="flex" align="center" justify="center">
					<Icon color="danger700" name="Loading" size="40px" />
          Loading...
        </Div>
			}
		>
			{children}
		</Suspense>
	</Div>
);
