import moment from "moment";

export const DateFormat = "DD/MM/YYYY";

export const ISODateFormat = "YYYY-MM-DD";

export const ISODateFormatTime = "YYYY-MM-DD hh:mm:ss";

const year = moment()
    .startOf("year")
    .format(DateFormat)
    .toString()
    .split("/")[2];
const month = moment()
    .startOf("month")
    .format(DateFormat)
    .toString()
    .split("/")[1];

export const getToDay = () => moment();

export const getFirtDayOfWeek = () => moment().startOf("isoWeek");

export const getLastDayOfWeek = () => moment().endOf("isoWeek");

export const getFirtDayOfMonth = () => moment().startOf("month");

export const getLastDayOfMonth = () => moment().endOf("month");

export const getFirtDayOfYear = () => moment().startOf("year");

export const getLastDayOfYear = () => moment().endOf("year");

export const getQuy = (type: number) => {
    let arrDate = [];
    if (type === 0) {
        if (parseInt(month) >= 1 && parseInt(month) <= 3) type = 1;
        if (parseInt(month) >= 4 && parseInt(month) <= 6) type = 2;
        if (parseInt(month) >= 7 && parseInt(month) <= 9) type = 3;
        if (parseInt(month) >= 10 && parseInt(month) <= 12) type = 4;
    }
    if (type === 1) {
        arrDate[0] = moment(new Date("Jan 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Mar 01 " + year)).endOf("month");
    }
    if (type === 2) {
        arrDate[0] = moment(new Date("Apr 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Jun 01 " + year)).endOf("month");
    }
    if (type === 3) {
        arrDate[0] = moment(new Date("Jul 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Sep 01 " + year)).endOf("month");
    }
    if (type === 4) {
        arrDate[0] = moment(new Date("Oct 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Dec 01 " + year)).endOf("month");
    }
    return arrDate;
};

export const getThang = (type: number) => {
    let arrDate = [];
    if (type === 1) {
        arrDate[0] = moment(new Date("Jan 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Jan 01 " + year)).endOf("month");
    }
    if (type === 2) {
        arrDate[0] = moment(new Date("Feb 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Feb 01 " + year)).endOf("month");
    }
    if (type === 3) {
        arrDate[0] = moment(new Date("Mar 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Mar 01 " + year)).endOf("month");
    }
    if (type === 4) {
        arrDate[0] = moment(new Date("Apr 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Apr 01 " + year)).endOf("month");
    }
    if (type === 5) {
        arrDate[0] = moment(new Date("May 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("May 01 " + year)).endOf("month");
    }
    if (type === 6) {
        arrDate[0] = moment(new Date("Jun 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Jun 01 " + year)).endOf("month");
    }
    if (type === 7) {
        arrDate[0] = moment(new Date("Jul 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Jul 01 " + year)).endOf("month");
    }
    if (type === 8) {
        arrDate[0] = moment(new Date("Aug 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Aug 01 " + year)).endOf("month");
    }
    if (type === 9) {
        arrDate[0] = moment(new Date("Sep 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Sep 01 " + year)).endOf("month");
    }
    if (type === 10) {
        arrDate[0] = moment(new Date("Oct 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Oct 01 " + year)).endOf("month");
    }
    if (type === 11) {
        arrDate[0] = moment(new Date("Nov 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Nov 01 " + year)).endOf("month");
    }
    if (type === 12) {
        arrDate[0] = moment(new Date("Dec 01 " + year)).startOf("month");
        arrDate[1] = moment(new Date("Dec 01 " + year)).endOf("month");
    }
    return arrDate;
};

export const formatDate = (str?: string) => {
    if (!!!str) return "";
    let strArr = str.split(" ");
    let date = strArr[0].split("-");
    return date[2].slice(0, 2) + "/" + date[1] + "/" + date[0];
};
export const formatDateISO = (str: string, format: string) => {
    if (!!!str) return "";
    return moment(str).format(format.toUpperCase()).toString();
};