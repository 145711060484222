// import modular routes
import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from "../pages/LoginPage";
import Dashboard from "../pages/Dashboard";

const routes = [
    {
        exact: true,
        path: "/",
        component: LoginPage
    },
    {
        path: "/dapp",
        component: Dashboard
    },
    {
        component: NotFoundPage
    }
];
export default routes;