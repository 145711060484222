import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import { configure } from 'mobx';

import authStore from './authStore';
import layoutStore from './layoutStore';
import Globalstore from './globalstore';
import VanBanDiStore from './vanbandistore';
import VanBanDenStore from './vanbandenstore';
import VanBanNoiBoStore from './vanbannoibostore';
configure({useProxies: "never", enforceActions: "never"});

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');
export const storesContext = React.createContext({
  authStore: new authStore(),
  layoutStore: new layoutStore(),
  vanBanDiStore: new VanBanDiStore(),
  vanBanDenStore: new VanBanDenStore(),
  vanBanNoiBoStore: new VanBanNoiBoStore(),
  globalstore: new Globalstore(),
});

export const useStores = () => React.useContext(storesContext);
