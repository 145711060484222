
import { FileSearchOutlined } from "@ant-design/icons"

export const APP_TITLE = "MDoc";
export const PUBLIC_ROOT = "/";
export const PRIVATE_ROOT = "/dapp/home";
export const DASHBOARD_ROOT = "";
export const baseURL = "http://103.161.173.225:4001/api/v1";
export const homeURL = "http://103.161.173.225:4001:4001";

export const reports = [
    {
        id: 0,
        slug: "home",
        module: "Trang chủ"
    },
    {
        id: 1,
        slug: "van-ban-den",
        title: "Văn bản đến",
        module: "VanBanDen",
        icon: FileSearchOutlined,
        color: '#52c41a',
        image: 'den.png'
    },
    {
        id: 2,
        slug: "vanbandi",
        title: "Văn bản đi",
        module: "VanBanDi",
        icon: FileSearchOutlined,
        color: '#007ACC',
        image: 'di.png'
    },
    {
        id: 3,
        slug: "van-ban-noi-bo",
        title: "Văn bản nội bộ",
        module: "VanBanNoiBo",
        icon: FileSearchOutlined,
        color: '#eb2f96',
        image: 'noibo.png'
    },
];

export const settings = {
    background: "#EBEBF3",
    color: "#292A2B",
    bmBurgerBars: "#81738B ",
    colorCollapses: ["#007ACC", "#DDA2F6"],
    menu: ["300px", "80px"],
    collapseButton: ["290px", "70px"]
}
