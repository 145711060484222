import React from "react";
import { Div, Text } from "atomize";
import { PoweroffOutlined } from '@ant-design/icons';
import { useStores } from '../../../store';

import { HStack, HoverButton } from "../../shared";


const LogoutButton: React.FC = () => {
	const {
		authStore: { logout }
	} = useStores();
	return (
		<Div
			w="100%"
			cursor="ponter"
			hoverBg="#FFFFFF"
			onClick={logout}
		>
			<HStack
				w="100%"
				h="100%"
				align="center"
			>
				<HoverButton
					isactive={false}
					icon={PoweroffOutlined}
				/>
				<Text
					textSize="caption"
					transition="linear"
					textWeight="500"
					m={{ l: "0.3rem" }}
					cursor="pointer"
				>
					Thoát
        </Text>
			</HStack>
		</Div>
	);
};

export default LogoutButton;
