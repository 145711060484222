import { makeAutoObservable, action, observable } from "mobx";

interface LayoutStoreProps {
    collapsed: boolean
    toogleSide: () => any
}

export default class LayoutStore implements LayoutStoreProps {

    @observable
    collapsed = true;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    toogleSide = () => this.collapsed = !this.collapsed
}
