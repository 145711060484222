import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Div } from 'atomize';
import { observer } from 'mobx-react';

import { AppLogo } from './AppLogo';
import { VStack } from '../../shared';
import { settings } from '../../../const/config';
import { useStores } from '../../../store';
import { DMenu } from "./SiderMenu";

const styles = {
	bmBurgerButton: {
		position: 'fixed',
		width: '24px',
		height: '20px',
		right: '24px',
		top: '10px',
	},
	bmBurgerBars: {
		background: settings.bmBurgerBars,
	},
	bmBurgerBarsHover: {
		background: settings.color,
	},
	bmCrossButton: {
		height: '24px',
		width: '24px',
	},
	bmCross: {
		background: settings.color,
	},
	bmMenuWrap: {
		position: 'fixed',
		height: '100%',
	},
	bmMenu: {
		background: '#fff',
		padding: '2.5em 1.5em 0',
		fontSize: '1.15em',
	},
	bmMorphShape: {
		fill: settings.color,
	},
	bmItemList: {
		color: settings.color,
		position: 'relative',
	},
	bmItem: {
		display: 'inline-block',
		width: '100%',
	},
	bmOverlay: {
		background: 'rgba(0, 0, 0, 0.3)',
	},
};

const MobileMenu: React.FC =
	observer(() => {
		const {
			authStore: { username },
		} = useStores();
		return (
			// @ts-ignore
			<Menu
				isOpen={false}
				pageWrapId={'app'}
				outerContainerId={'root'}
				styles={styles}
			>
				<Div>
					<VStack w="100%" h="100%" align="center" justify="center">
						<Div p={{ b: '1rem' }}>
							<AppLogo username={username} />
						</Div>
						<DMenu />
					</VStack>
				</Div>
			</Menu>
		);
	});
export default MobileMenu;
