
const them = {
    colors: {
        bg: "#434B53",
        menu: "#967BDD",
        menuitem: "#967BDD",
        maincontent: "#E7E9ED",
        txtdark: "#373a47",
        primary: 'tomato',
        accent: 'yellow',
        panel: '#FFFFFF',
        color1: "#CCD1D8",
        color2: "#5E9CEB",
        color3: "#48CFAD",
        color4: "#FFCF54",
        color5: "#EC5664"
    },
    transition: {
        collapse: "all 0.8s linear",
        linear: "all 2s linear",
        cubic: "all 0.3s cubic-bezier(.785,.135,.15,.86) 0s",
        display: "display 0.3s cubic-bezier(.785,.135,.15,.86) 0s",
    }
};

export default them;