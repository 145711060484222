import React from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { PUBLIC_ROOT, PRIVATE_ROOT } from "../const/config";
import { useStores } from "../store";

const Resolver: React.FC<any> = props => {
	const { isAuthenticated, component } = props;
	const { isPrivate } = component;
	let redirect;

	if (isAuthenticated && !isPrivate) {
		redirect = PRIVATE_ROOT;
	}

	if (!isAuthenticated && isPrivate) {
		redirect = PUBLIC_ROOT;
	}

	const Component = component;
	return redirect ? <Redirect to={redirect} /> : <Component {...props} />;
};


const Injected = inject(() => ({
	isAuthenticated: useStores().authStore.isAuthenticated
}))(observer(Resolver));

const Auth = (Comp: any) => (props: any) => {
	return <Injected {...props} component={Comp} />;
};

export default Auth;
