import * as dates from './Date';
// import { DCauHinh } from '../store/graphql.schema';
import moment from 'moment';

export const DateFormat = 'DD/MM/YYYY';

export const ISODateFormat = 'YYYY-MM-DD HH:mm:ss.SSS';
export const ISODateFormatTime = 'YYYY/MM/DD HH:mm';

export const covertToFormatNotime = (cauhinh?: any): string => {
  if (!cauhinh) return DateFormat;
  else {
    const { DINH_DANG_NGAY_GIO } = cauhinh;
    return DINH_DANG_NGAY_GIO ? DINH_DANG_NGAY_GIO.toUpperCase() : DateFormat;
  }
};
export const covertToFormat = (cauhinh?: any): string => {
  if (!cauhinh) return DateFormat;
  else {
    const { DINH_DANG_NGAY_GIO, HIEN_THI_GIO } = cauhinh;
    let format = DINH_DANG_NGAY_GIO
      ? DINH_DANG_NGAY_GIO.toUpperCase()
      : DateFormat;
    return HIEN_THI_GIO ? format + ' HH:mm' : format;
  }
};
export const covertToFormatMoment = (
  source?: string,
): string => {
  if (!source) return '';
  else {
    let format =  DateFormat.toUpperCase();
    return moment(source).format(format).toString();
  }
};

export interface ITimeSpan {
  value: string;
  label: string;
  start?: any;
  end?: any;
}

export const timeSpans: Array<ITimeSpan> = [
  {
    value: "0",
    label: 'Hôm nay',
    start: dates.getToDay(),
    end: dates.getToDay(),
  },
  {
    value: "1",
    label: 'Tuần này',
    start: dates.getFirtDayOfWeek(),
    end: dates.getLastDayOfWeek(),
  },
  {
    value: "2",
    label: 'Tháng này',
    start: dates.getFirtDayOfMonth(),
    end: dates.getLastDayOfMonth(),
  },
  {
    value: "3",
    label: 'Năm nay',
    start: dates.getFirtDayOfYear(),
    end: dates.getLastDayOfYear(),
  },
  {
    value: "4",
    label: 'Quý này',
    start: dates.getQuy(0)[0],
    end: dates.getQuy(0)[1],
  },
  {
    value: "5",
    label: 'Quý 1',
    start: dates.getQuy(1)[0],
    end: dates.getQuy(1)[1],
  },
  {
    value: "6",
    label: 'Quý 2',
    start: dates.getQuy(2)[0],
    end: dates.getQuy(2)[1],
  },
  {
    value: "7",
    label: 'Quý 3',
    start: dates.getQuy(3)[0],
    end: dates.getQuy(3)[1],
  },
  {
    value: "8",
    label: 'Quý 4',
    start: dates.getQuy(4)[0],
    end: dates.getQuy(4)[1],
  },
  {
    value: "9",
    label: 'Tháng 1',
    start: dates.getThang(1)[0],
    end: dates.getThang(1)[1],
  },
  {
    value: "10",
    label: 'Tháng 2',
    start: dates.getThang(2)[0],
    end: dates.getThang(2)[1],
  },
  {
    value: "11",
    label: 'Tháng 3',
    start: dates.getThang(3)[0],
    end: dates.getThang(3)[1],
  },
  {
    value: "12",
    label: 'Tháng 4',
    start: dates.getThang(4)[0],
    end: dates.getThang(4)[1],
  },
  {
    value: "13",
    label: 'Tháng 5',
    start: dates.getThang(5)[0],
    end: dates.getThang(5)[1],
  },
  {
    value: "14",
    label: 'Tháng 6',
    start: dates.getThang(6)[0],
    end: dates.getThang(6)[1],
  },
  {
    value: "15",
    label: 'Tháng 7',
    start: dates.getThang(7)[0],
    end: dates.getThang(7)[1],
  },
  {
    value: "16",
    label: 'Tháng 8',
    start: dates.getThang(8)[0],
    end: dates.getThang(8)[1],
  },
  {
    value: "17",
    label: 'Tháng 9',
    start: dates.getThang(9)[0],
    end: dates.getThang(9)[1],
  },
  {
    value: "18",
    label: 'Tháng 10',
    start: dates.getThang(10)[0],
    end: dates.getThang(10)[1],
  },
  {
    value: "19",
    label: 'Tháng 11',
    start: dates.getThang(11)[0],
    end: dates.getThang(11)[1],
  },
  {
    value: "20",
    label: 'Tháng 12',
    start: dates.getThang(12)[0],
    end: dates.getThang(12)[1],
  },
  {
    value: "21",
    label: 'Tự thiết lập',
  },
];

export const DataKieuSP = [
  {
    value: '-1',
    label: 'Tất cả',
  },
  {
    value: '0',
    label: 'Sản phẩm nhập',
  },
  {
    value: '1',
    label: 'Thành phẩm',
  },
  {
    value: '2',
    label: 'Nguyên vật liệu',
  },
];
export const DataFilterSP = [
  {
    id: '0',
    name: 'Tất cả',
  },
  {
    id: '1',
    name: 'Loại khác',
  },
];
