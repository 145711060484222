import React from 'react';
import { Button } from 'antd';
import { ThunderboltFilled } from '@ant-design/icons';
import { Div, Text } from 'atomize';
import { PageHeader, notification } from 'antd';

export * from './DForm';
export * from './DFormField';
export * from './DCheckBox';
export * from './DTextInput';
export * from './DTextArea';
export * from './Notification';
export * from './DSelect';
export * from './DMSelectSanPham';
export * from './DMSelectKhachHang';
export * from './DMutilSelect';
export * from './DDatePicker';
export * from './DNumberFormat';
export * from './DTablePagination';

type HoverProps = {
	isactive?: boolean;
	icon: any;
	onClick?: any;
	style?: any;
};

export const HoverButton: React.FC<HoverProps> = ({
	isactive,
	onClick,
	style,
	icon,
}) => {
	const Icon = icon;
	return (
		<Icon
			onClick={onClick}
			style={{
				...style,
				cursor: 'pointer',
				outline: 'none',
				color: isactive ? '#dd5145!important' : 'none!important',
			}}
		/>
	);
};

export const SubmitButton: React.FC<{ onClick?: any, title?: string, icon?: any, width?: string }> = ({ title, onClick, icon, width }) => (
	<Button
		type="primary"
		htmlType="submit"
		onClick={onClick}
		icon={icon ? icon : <ThunderboltFilled />}
		style={{ width: width ? width : '100%', float: 'right' }}
	>
		{title ? title : 'Nạp dữ liệu'}
	</Button>
);

type props = {
	w?: string | number | object;
	h?: string | number | object;
	p?: string | number | object;
	m?: string | number | object;
	bg?: string;
	border?: string | object;
	pos?: string;
	flexDir?: string | object;
	flexWrap?: string;
	borderColor?: string;
	justify?: string | object;
	rounded?: string;
	align?: string | object;
	style?: object;
	transition?: string;
	overflow?: string;
	children?: any;
};

export const VStack: React.FC<props> = ({
	w,
	h,
	p,
	m,
	pos,
	border,
	borderColor,
	rounded,
	justify,
	align,
	style,
	transition,
	children,
}) => (
	<Div
		d="flex"
		flexDir="column"
		w={w}
		h={h}
		p={p}
		m={m}
		pos={pos}
		border={border}
		borderColor={borderColor}
		rounded={rounded}
		justify={justify}
		align={align}
		transition={transition}
		style={style}
	>
		{children}
	</Div>
);
export const HStack: React.FC<props> = ({
	w,
	h,
	p,
	m,
	border,
	pos,
	flexWrap,
	flexDir = 'row',
	borderColor,
	justify,
	align,
	rounded,
	style,
	bg,
	transition,
	children,
	overflow
}) => (
	<Div
		d="flex"
		flexDir={flexDir}
		w={w}
		h={h}
		p={p}
		m={m}
		pos={pos}
		border={border}
		bg={bg}
		borderColor={borderColor}
		rounded={rounded}
		justify={justify}
		align={align}
		transition={transition}
		style={style}
		flexWrap={flexWrap}
		overflow={overflow}
	>
		{children}
	</Div>
);
export const Panel: React.FC<{
	border?: string | object;
	p?: string | object;
	h?: string | object;
	title?: string;
	children?: any;
}> = ({ h, p, border, title, children }) => (
	<Div h={h} border={border} borderColor="txtdark" w="100%" m={{ b: '1rem' }}>
		{title && (
			<Text tag="label" textSize={{ md: 'title', xs: 'subheader' }}>
				{title}
			</Text>
		)}
		<Div
			m={{ t: '0.5rem' }}
			p={p}
			bg="panel"
			w="100%"
			h="100%"
			d="flex"
			flexDir="column"
		>
			{children}
		</Div>
	</Div>
);

export const LeftPanel: React.FC<{ children?: any }> = ({ children }) => (
	<Div
		// h="100%"
		p="0.5rem"
		w={{ lg: '30%', md: '100%' }}
		order={{ lg: '2', md: '1', sm: '1', xs: '1' }}
	>
		<VStack>{children}</VStack>
	</Div>
);
export const RightPanel: React.FC<{ children?: any }> = ({ children }) => (
	<Div
		h="100%"
		p="0.5rem"
		w={{ lg: '70%', md: '100%' }}
		order={{ lg: '1', md: '2', sm: '2', xs: '2' }}
	>
		<VStack>{children}</VStack>
	</Div>
);
export const MainPanel: React.FC<{ children?: any; title?: string }> = ({
	children,
	title,
}) => (
	<HStack
		h="100%"
		flexDir={{
			xs: 'column',
			sm: 'column',
			md: 'column',
			lg: 'row',
			xl: 'row',
		}}

	>
		<Div h="100%" w="100%" bg="white" style={{paddingTop: 55}}>
			<Div bg="panel" p="0.5rem" h="100%" w="100%" style={{
				position: 'relative',
				overflow: 'hidden', 
				marginTop: -55
			}}>
				<PageHeader
					ghost={false}
					onBack={() => window.history.back()}
					title={title}
				/>
				<Div h="calc(100% - 44px)" w="100%" className="main-content" style={{ overflow: 'hidden', overflowY: "auto" }}>
					{children}
				</Div>
			</Div>
		</Div>
	</HStack>
);

type layoutProps = {
	children: any;
	title?: string;
};

export const Content: React.FC<layoutProps> = ({ title, children }) => (
	<Panel p="1rem" title={title}>
		<VStack w="100%">{children}</VStack>
	</Panel>
);

export const openNotificationWithIcon = (type: 'error' | 'success', message: string) => {
	notification[type]({
		message: type === 'error' ? 'Lỗi' : '',
		description: message,
	});
};